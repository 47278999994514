import React, { useState } from 'react';
import { ModalWrapper, ModalContent , CloseButton, StyledTopBar, Title, ModalInfoIntern} from './styles';
import RotaInfoButton from '../RotaInfoButton';
import RotaInfoView from '../RotaInfoView';

const ModalInfo = ({ onClose, rota }) => {
  
  const [viewRota, setViewRota] = useState(0);

  console.log(rota);

  const renderRotaComponents = () => {
    const components = [];
    for (let i = 1; i <= rota.num_linhas; i++) {
      components.push(
        <React.Fragment key={`fragment-${i}`}>
          <RotaInfoButton 
            rota={rota[`linha_${i}`].linha} 
            onClick={() => setViewRota(i)} 
          />
          {viewRota === i && (
            <RotaInfoView 
              dados={rota[`linha_${i}`]} 
            />
          )}
        </React.Fragment>
      );
    }
    return components;
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <StyledTopBar>
          <Title>Informações das Rotas</Title>
        </StyledTopBar>
        <CloseButton onClick={onClose}>X</CloseButton>
        <ModalInfoIntern>
          {renderRotaComponents()}
        </ModalInfoIntern>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ModalInfo;
