import './App.css';
import Login from './pages/login';
import Routers from './data/routes';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return ( 
    <Router basename="vt_site/html2">
      <Routers />
      </Router>
  );
}

export default App;