import { SelectCityWrapper } from "../../../pages/home/styles";
import { ExternDiv, InputContainer, InputField, Label, SelectWrapper, TitleLabel } from "./styles"
import React, { useEffect, useState } from "react"

const OptionSelector = ({ onChange, selected }) => {
    return (
        <ExternDiv>
            <SelectWrapper>
                <TitleLabel>
                    Tipo de Consulta
                </TitleLabel>
            </SelectWrapper>
            <SelectWrapper>
                <InputContainer>
                    <Label>
                        <InputField
                            type="radio"
                            value="TOD"
                            checked={selected === "TOD"}
                            onChange={onChange}                   
                        />
                        Trajeto Origem-Destino
                    </Label>
                    <Label>
                        <InputField
                            type="radio"
                            value="MU"
                            checked={selected === "MU"}
                            onChange={onChange}
                        />
                        Melhor Unidade
                    </Label>
                </InputContainer>
            </SelectWrapper>
        </ExternDiv>
    );
};

export default OptionSelector;