import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto, cinza_texto_light } from '../../../data/color';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: ${branco_primario};
  border-radius: 2px;
  position: relative;
  width: 30%;
  height: 60%;
  overflow-y: auto;
  box-shadow: 0px 0px 20px;
  margin-top: 5%;
  margin-left: 70%;
`;

export const ModalInfoIntern = styled.div`
  position: absolute;
  width: 100%;
  height: 83%;
  margin-top: 15%;
  border-width: 0px;
  background-color: none;
`;

export const InfoLabelWrapper = styled.div`
  margin-left: 5%;
  margin-top: 3%;
  width: 90%;
`;

export const Divider = styled.div`
  border-top: 1px solid ${cinza_texto_light};
  margin-left: 5%;
  margin-top: 3%;
  margin-botton: 3%;
  width: 90%;
  weight: 3%;
`;

export const InfoLabel = styled.label`
  top: 0%;
  color: ${cinza_texto};
  font-size: 16px;
  text-align: left;
  font-family: ${font_base};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: ${cinza_texto};
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: ${font_base};
  &:hover {
    color: white;
  }
 
`;

export const StyledTopBar = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%; 
  height: 15%;
  background-color: ${azul_primario}; 
 
`;

export const  Title = styled.h1`
  font-size: 25px;
  color: ${branco_primario};
  margin: 10px;
  padding: 0;
  text-align: center;
  line-height: 50px;
  margin-top: 20;
  font-family: ${font_base};
`;

export const FecharButton = styled.button`
  background-color: ${branco_primario};
  color: ${azul_primario};
  height: 50px;
  width: 100px;
  border: none;
  border-radius: 20px;
  border:2px solid ${azul_primario};
  position: absolute;
  margin-top: 20%;
  margin-left: 70%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-family: ${font_base};
`;