import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto, cinza_texto_light } from '../../../data/color';

// Definindo o componente de Select
export const SelectWrapper = styled.div`
  position: fixed; /* Alterado para fixed */
`;
export const Select = styled.select`
  width: 90px;
  height: 40px; 
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  appearance: none;
  background-color: ${branco_primario};
  margin-left: 80px;
  border: 1px solid ${cinza_texto_light};
  color: ${cinza_texto};  
  cursor: pointer;
  font-family: ${font_base};
`;

export const ArrowIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;
