import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  background-color: ${({ isClicked }) => (isClicked ? branco_primario : branco_primario)};
  color: ${azul_primario};
  height: 50px;
  width: 100px;
  padding: 0.8% 20%;
  border: none;
  border-radius: 20px;
  border:2px solid ${azul_primario};
  margin-left: auto; /* Move o botão para a margem direita */
  transition: background-color 0.3s; /* Adiciona uma transição suave */
  font-family: ${font_base};
  &:hover {
    background-color: ${azul_primario}; /* Cor ao passar o mouse */
    color: ${branco_primario};
  }

`; 
