import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';
import { font_base } from '../../../data/font';
import styled from 'styled-components';

export const NumberSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${cinza_texto};
  margin: 5px auto;
  width: 90%;
  position: relative;
  top: -80px;
  @media (max-width: 768px) {
    width: 90%;
    top: -60px;
  }
`;

export const NumberInput = styled.input`
  padding: 8px;
  border: none;
  border-bottom: 1px solid ${cinza_texto};
  font-size: 16px;
  width: 80%;
  position: relative;
  left: 0px;
  font-family: ${font_base};

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 6px;
    left: 10px;
  }
  outline: none;
  background-color: ${branco_primario};
`;

export const InputLabel = styled.label`
  top: 0%;
  color: ${cinza_texto};
  font-size: 16px;
  font-weight: 300;
  pointer-events: none;
  text-align: left;
  position: relative;
  left: -1px;
  font-family: ${font_base};
`;
