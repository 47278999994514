import login from '../../data/services/login';
import React, { useState, useEffect } from 'react';
import Message from '../../utils/components/Message';
import SubTitle from '../../utils/components/SubTitle';
import SaveButton from '../../utils/components/SaveButton';
import InputFields from '../../utils/components/InputFields';
import { useNavigate } from "react-router-dom";
import { Container, DepthBox, LoadingContainer, LoadingSpinner } from './styles';
import { consultaPath, loginPath } from '../../data/pathConfig';
import getTimeOut from '../../data/services/getTimeOut';

const Login = () => {
  const [User, setUser] = useState('');
  const [Password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');

  const handleSaveClick = async () => {
    if (!User || !Password) {
      setErrorMessage('Preencha todos os campos!');
      return;
    }
    setLoading(true);

    const loginParams = {
      login: User,
      password: Password,
    };
    const response = await login(loginParams);
    console.log(response);
    if (response.success === false) {
      setErrorMessage('Login ou senha incorretos!');
      setLoading(false);
      return;
    } else {
      localStorage.clear();
      localStorage.setItem('token', response.data.token);
      setToken(response.data.token);
      console.log("Logando");
      //navigate(consultaPath);
      console.log("Navegou para consulta");
    }
  };

  useEffect(() => {
    const verify = async (token) => {
      const data = await getTimeOut(token);
      if(data.success) {
        console.log("Navegando");
        navigate(consultaPath);
        setLoading(false);
      } else {
        setErrorMessage('Login ou senha incorretos!');
        setLoading(false);
      }
    }
  
    if (token) {
      verify(token);      
    } else {
      navigate(loginPath);
    }
  }, [token]);

  return (
    <Container>
      <DepthBox>
        <img src={require('../../utils/images/blog.png')} alt="Blog" />
        <InputFields
          label="Login"
          name="nome"
          value={User}
          placeholder=" "
          onChange={(e) => setUser(e.target.value)}
        />
        <InputFields
          label="Senha"
          name="data"
          value={Password}
          placeholder=" "
          isPassword={true}
          onChange={(e) => setPassword(e.target.value)}
        />
        <SaveButton onClick={handleSaveClick}>Entrar</SaveButton>
        <Message type="error">{errorMessage}</Message>
        {loading && (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        )}
      </DepthBox>
    </Container>
  );
};

export default Login;
