import React, { useState } from 'react';
import { ButtonContainer, Button } from './styles'; 

const LogoutButton = ({ onClick, children }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(true);
    onClick();
  };

  return (
    <ButtonContainer>
      <Button
        onClick={handleButtonClick}
        isClicked={isClicked}
      >
        {children}
      </Button>
    </ButtonContainer>
  );
};

export default LogoutButton;
