import styled from 'styled-components';
import { font_base } from '../../../data/font';

export const PopUpWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const PopUpContent = styled.div`
  background: #fff;
  border-radius: 2px;
  position: relative;
  width: 25%;
  height: 10%;
  overflow-y: auto;
  box-shadow: 0px 0px 20px;
  margin-top: 42%;
  margin-left: 73%;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #d3d3d3;
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: ${font_base};
  &:hover {
    color: white;
  }
 
`;

export const StyledTopBar = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%; 
  height: 100%;
  background-color: #0A1C5C; 
 
`;
export const  Title = styled.h1`
  font-size: 15px;
  color: ${(props) => (props.textColor)};
  margin: 10px;
  padding: 0;
  text-align: center;
  line-height: 50px;
  margin-top: 20;
  font-family: ${font_base};

`;