import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_borda_light, cinza_texto } from '../../../data/color';

// Wrapper que engloba o select e a seta
export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${cinza_texto};
  margin: 1px auto;
  width: 90%;
  position: relative;
  top: -80px;
  @media (max-width: 768px) {
    width: 90%; 
    top: -40px;  
  }
  padding: 5px;
`;

// Estilo para o select
export const SelectStyle = styled.select`
  width: 90%;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  appearance: none;
  background-color: ${branco_primario};
  border: 1px solid ${cinza_borda_light};
  color: ${cinza_texto};
  cursor: pointer;
  margin-left: auto;
  position: relative;
  left: 40px;
  font-family: ${font_base};
`;

// Ícone de seta
export const ArrowIcon = styled.span`
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
`;

// Label para o select
export const Label = styled.label`
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
  color: ${cinza_texto};
  transform-origin: 0 100%;
  font-size: 16px;
  font-weight: 300;
  font-family: ${font_base};
`;
