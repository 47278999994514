import styled from 'styled-components'
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const ModalContent = styled.div`
  background: ${branco_primario};
  border-radius: 2px;
  position: relative;
  width: 30%;
  height: 65%;
  overflow-y: auto;
  box-shadow: 0px 0px 20px;
  margin-left: 35%;
  margin-top: 7%;
`;

export const StyledTopBar = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%; 
  height: 20%;
  background-color: ${azul_primario}; 
 
`;

export const  Title = styled.h1`
  font-size: 25px;
  color: ${branco_primario};
  margin: 10px;
  padding: 0;
  text-align: center;
  line-height: 50px;
  margin-top: 20;
  font-family: ${font_base};
`;

export const BlockTitle = styled.h4`
  font-size: 14px;
  color: ${cinza_texto};
  padding-left: 5px;
  font-family: ${font_base};
`;

export const Text = styled.p`
  font-size: 16px;
  color: ${cinza_texto};
  padding-left: 5px;
  font-family: ${font_base};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: ${branco_primario};
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: ${font_base};
 
`;

export const ContentDiv = styled.div`
  width: 100%;
  height: 50%;
  background-color: none;
  overflow-y: auto;
  margin-top: 3%;
  position: static;
`;

export const InfoBlock = styled.div`
  width: 100%;
  height: 60%;
  background-color: none;
  position: static;

`;

export const TextDiv = styled.div`
  width: 100%;
  heigth: 5%
  background-color: ${branco_primario};
  margin-top: 25%;
  position: static;
  color: ${cinza_texto};
  font-size: 13px;
`;

export const GerarButtonWrapper = styled.div`
  width: 30%;
  height: 15%;
  margin-top: 2%;
  margin-left: 70%;
  background-color: none;
  position: static;
  display: flex;
  justify-content: center;
`;