import React, { useState } from 'react';
import { ModalWrapper, ModalContent , CloseButton, StyledTopBar, Title} from './styles';

const ModalPassword = ({ isOpen, onClose, children }) => {
    return (
      isOpen && (
        <ModalWrapper>
          <ModalContent>
            <StyledTopBar>
              <Title>Trocar Senha</Title>
            </StyledTopBar>
            <CloseButton onClick={onClose}>X</CloseButton>
            {children}
          </ModalContent>
        </ModalWrapper>
      )
    );
  };

export default ModalPassword;
