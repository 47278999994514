import React, { useState } from 'react';
import { InputContainer, InputLabel, InputField, EyeIcon } from './styles';
import InputMask from 'react-input-mask';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const GetData = ({ name, label, value, placeholder, onChange, mask, isPassword }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    const sanitizedValue = e.target.value.replace(/\./g, ',');
    onChange({ target: { name, value: sanitizedValue } });
  };

  return (
    <InputContainer>
      <InputLabel>{label}</InputLabel>
      {mask ? (
        <InputMask
          mask="99/99/9999"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        >
          {(inputProps) => (
            <InputField
              type={isPassword && !showPassword ? 'password' : 'text'}
              name={name}
              value={value}
              placeholder={placeholder}
              onChange={handleInputChange}
            />
          )}
        </InputMask>
      ) : (
        <InputField
          type={isPassword && !showPassword ? 'password' : 'text'}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={handleInputChange}
        />
      )}
      {isPassword && (
        <EyeIcon onClick={handleTogglePassword}>
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </EyeIcon>
      )}
    </InputContainer>
  );
};

export default GetData;
