import {React, useState} from "react";
import { CampoTitulo, CampoDados, Linha } from "./styles";

import { FaStarOfLife, FaMoneyBillWave, FaPen, FaClock, FaPersonWalking, FaBus } from "react-icons/fa6";

const RotaInfoView = ({dados}) => {

    return (
        <>
            <CampoTitulo>
                <FaStarOfLife style={{color: '#4D4D4D', fontSize: '10px'}} />
                <h> Sistema: </h>
            </CampoTitulo>
            <CampoDados>
                <h>{dados.sistema}</h>
            </CampoDados>
            <Linha/>
            <CampoTitulo>
                <FaMoneyBillWave style={{color: '#4D4D4D', fontSize: '10px'}} />
                <h> Tarifa: </h>
            </CampoTitulo>
            <CampoDados>
                <h>R$ {dados.tarifa.toFixed(2).toString().replace(".", ",")}</h>
            </CampoDados>
            <Linha/>
            <CampoTitulo>
                <FaPen style={{color: '#4D4D4D', fontSize: '10px'}} />
                <h> Descrição: </h>
            </CampoTitulo>
            <CampoDados>
                <h>{dados.descricao}</h>
            </CampoDados>
            <Linha/>
            <CampoTitulo>
                <FaClock style={{color: '#4D4D4D', fontSize: '10px'}} />
                <h> Tempo: </h>
            </CampoTitulo>
            <CampoDados>
                <h>{dados.tempo} min</h>
            </CampoDados>
            <Linha/>
            <CampoTitulo>
                <FaPersonWalking style={{color: '#4D4D4D', fontSize: '10px'}} />
                <h> Distância para Embarque: </h>
            </CampoTitulo>
            <CampoDados>
                <h>{(dados.distancia_embarque*1000).toFixed(2)} m</h>
            </CampoDados>
            <Linha/>
            <CampoTitulo>
                <FaBus style={{color: '#4D4D4D', fontSize: '10px'}} />
                <h> Distância Embarcada: </h>
            </CampoTitulo>
            <CampoDados>
                <h>{dados.distancia_embarcada.toFixed(2)} km</h>
            </CampoDados>
        </>
    );
};

export default RotaInfoView;