import React from 'react';
import { FaUser } from 'react-icons/fa';
import { ProfileName, ProfileContainer, IconWrapper } from './styles';

// Componente de perfil simplificado com ícone da react-icons
const Profile = ({ name }) => {
    return (
        <ProfileContainer>
            <IconWrapper>
                <FaUser size={15} color="white" />
            </IconWrapper>
            <ProfileName>{name}</ProfileName>
            
        </ProfileContainer>
    );
};

export default Profile;

