import axios from "axios";
import { apiPath } from '../../pathConfig';

const getTimeOut = async (token) => {
    const formData = new FormData();
    formData.append('token', token)
    formData.append('function', 'getTimeOut');
    const response = await axios.post(apiPath, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
};

export default getTimeOut;