import React, { useState } from 'react';
import {
  NumberSelectorWrapper,
  NumberInput,
  InputLabel
} from "./styles";

const NumberSelector = ({ label, initialValue = 0, onChange }) => {
  const [value, setValue] = useState(initialValue);

  const handleInputChange = (e) => {
    let newValue = e.target.value === '' ? '' : parseInt(e.target.value);
    setValue(newValue);
    onChange && onChange(newValue);
  };

  const handleBlur = () => {
    let newValue = value;
    if (value === '' || isNaN(value)) {
      newValue = 1000;
    } else if (value > 1500) {
      newValue = 1500;
    } else if (value < 300) {
      newValue = 300;
    }
    setValue(newValue);
  };

  return (
    <NumberSelectorWrapper>
      <InputLabel>{label}</InputLabel>
      <NumberInput
        type="number"
        value={value}
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
    </NumberSelectorWrapper>
  );
};

export default NumberSelector;
