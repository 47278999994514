import React from 'react';
import { SelectWrapper, SelectStyle, ArrowIcon, Label } from './styles';

const Select = ({ options, value, onChange, label, readOnly }) => {
  return (
    <SelectWrapper>
      <Label>{label}</Label>
      <SelectStyle value={value} onChange={onChange} disabled={readOnly}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectStyle>
      <ArrowIcon>&#x25BC;</ArrowIcon>
    </SelectWrapper>
  );
};

export default Select;
