import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${cinza_texto};
  margin: 2px auto;
  width: 90%;
  position: relative;
  top: -80px;
  @media (max-width: 768px) {
    width: 90%; 
    top: -60px;  
  }
`;

export const InputWrapperQtd = styled.div`
  display: flex;
  flex-direction: column;
`;


export const InputLabel = styled.label`
  flex: 0 0 auto; 
  margin-right: 10px;
  color: ${cinza_texto};
  font-size: 16px;
  font-weight: 300;
  font-family: ${font_base};
`;

export const InputField = styled.input`
  flex: 1; 
  padding: 8px;
  border: none;
  font-size: 16px;
  color: ${cinza_texto};
  outline: none;
  background-color: ${branco_primario};
  margin-left:-12px;
  font-family: ${font_base};
`;


export const EyeIcon = styled.span`
  position: absolute;
  top: 70%;
  right: 1%;
  transform: translateY(-50%);
  cursor: pointer;
  color: rgba(43, 160, 225, 1);
  font-family: ${font_base};

  @media (max-width: 768px) {
    right: 5%;
  }
`;
