import { azul_primario, azul_primario_light, branco_primario, cinza_texto, cinza_texto_light } from '../../../data/color';
import { font_base } from '../../../data/font';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  top: -70px;
  position: relative;
  margin: 2px auto;
  margin-left: 0px;
`;

export const Label = styled.label`
  font-size: 16px;
  margin-right: 10px;
  color: ${cinza_texto};
  font-weight: 300px;
  font-family: ${font_base};
`;

export const Select = styled.select`
  padding: 8px;
  font-size: 16px;
  border: 1px solid ${cinza_texto_light};
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  font-family: ${font_base};
  background-color: ${branco_primario};
`;

export const Option = styled.option`
  font-size: 16px;
  font-family: ${font_base};
`;