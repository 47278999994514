import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const MessageContainer = styled.div`
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 16px;
`;

export const ErrorMessage = styled(MessageContainer)`
  background-color: #ff6666;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: ${font_base};
`;

export const WarningMessage = styled(MessageContainer)`
  background-color: #ffd700;
  color: black;
  font-family: ${font_base};
`;
