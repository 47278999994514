import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
`;

export const ModalContent = styled.div`
  background: ${branco_primario};
  padding: 15px; 
  border-radius: 5px;
  position: absolute;
  width: 20%;
  height: 120px;
  max-height: 5%;
  border: 1px solid gainsboro;
  margin-top: 60px;
  margin-left: 70%;  
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 18px;
  color: red;
  background: none;
  border: none;
  cursor: pointer;
  font-family: ${font_base};
`;