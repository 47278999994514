import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const StyledTitle = styled.h2`
  font-size: 15px;
  margin-bottom: 30px;
  color: ${azul_primario};
  text-align: center;
  transition: color 0.3s;
  cursor: pointer;
  font-family: ${font_base};
  &:hover {
    color: ${azul_primario_light}; /* Nova cor do texto ao passar o mouse */
  }
`;