import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';


export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center; 
`;

export const Button = styled.button`
  background-color: ${({ isClicked, isConfirmed }) => (isConfirmed ? (isClicked ? azul_primario : azul_primario) : azul_primario_light)};
  color: ${branco_primario};
  height: 50px;
  width: 100px;
  /* padding: 0.8% 20%; */
  border: none;
  border-radius: 20px;
  border:2px solid;
  border-color: ${({isConfirmed}) => (isConfirmed ? azul_primario : azul_primario_light)};
  font-family: ${font_base};
  transition: background-color 0.3s; /* Adiciona uma transição suave */
  &:hover {
    background-color: ${({isConfirmed}) => (isConfirmed ? branco_primario : azul_primario_light)}; /* Cor ao passar o mouse */
    color: ${({isConfirmed}) => (isConfirmed ? azul_primario : branco_primario)}; ;
  }
`; 
