import React from 'react';
import { ErrorMessage, WarningMessage } from './styles'; 

const Message = ({ type, children }) => {
  if (children) {
    if (type === 'error') {
      return <ErrorMessage>{children}</ErrorMessage>;
    } else if (type === 'warning') {
      return <WarningMessage>{children}</WarningMessage>;
    }
  }

  return null;
};

export default Message;

