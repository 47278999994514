import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh; /* Ajuste a altura conforme necessário */
  position: relative;
`;

export const Button = styled.button`
  background-color: ${({ isClicked }) => (isClicked ? azul_primario : azul_primario)};
  color: ${branco_primario};
  padding: 0.8% 20%;
  border: none;
  font-size: 18px;
  border-radius: 0px;
  font-family: ${font_base};
  transition: background-color 0.3s; /* Adiciona uma transição suave */

  &:hover {
    background-color: ${azul_primario_light}; /* Cor ao passar o mouse */
  }

  @media (min-width: 768px) {
    width: 53%;
    max-width: 400px;
  }
`;