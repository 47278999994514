import { React, useState } from 'react';
import { ButtonWrapper, InfoButton, Paragraph } from './styles';

import { FaCaretDown, FaBus } from 'react-icons/fa';

const RotaInfoButton = ({onClick, rota}) => {
    return (
        <InfoButton onClick={onClick}>
            <FaBus style={{color: '#FFFFFF', fontSize: '20px'}} />
            {rota}
            <FaCaretDown style={{color: '#FFFFFF', fontSize: '20px'}}/>
        </InfoButton>
    );
};

export default RotaInfoButton;