import styled, { keyframes } from 'styled-components';
import backgroundImg from '../../utils/images/back_azul.png';
import { azul_primario, branco_primario } from '../../data/color';

export const Container = styled.div`
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; 
  min-height: 100vh; /* Altura mínima total da visualização */
  position: relative;
  @media (max-width: 768px) {
    padding: 0.0%;
  }
`;


export const DepthBox = styled.div`
  width: 35%; /* Largura relativa */
  height: 90%;
  background-color: ${branco_primario};
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  margin: 5% auto; /* Usando porcentagem para margem também */
  padding: 3vw; /* Unidade vw para tamanho responsivo */
  @media (max-width: 768px) {
    width: 90%;
    padding: 2vw;
  }
  img {
    display: flex;
    max-width: 40%;
    height: auto;
    border-radius: 5%; /* Alterei para 5% para manter a forma circular */
    margin-bottom: 4vw; /* Usando vw para margem inferior */
    margin-left: auto;
    margin-right: auto; /* Centralizei a imagem horizontalmente */
  }
`;

export const Heading = styled.h1`
  margin: 0;
`;

export const Content = styled.p`
  margin: 0;
`;
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999; 
`;

export const LoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: ${rotate} 1s linear infinite;
`;

