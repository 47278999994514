import React, { useEffect, useState } from 'react';
import { ModalWrapper, ModalContent , CloseButton, StyledTopBar, Title, ModalInfoIntern, InfoLabel, InfoLabelWrapper, Divider, FecharButton} from './styles';
import RotaInfoButton from '../RotaInfoButton';
import RotaInfoView from '../RotaInfoView';

const ModalInfoMU = ({ onClose, endereco, data }) => {


  
    return (
        <ModalWrapper>
          <ModalContent>
            <StyledTopBar>
              <Title>Funcionário</Title>
            </StyledTopBar>
            <CloseButton onClick={onClose}>X</CloseButton>
            <ModalInfoIntern>
              <InfoLabelWrapper>
                <InfoLabel>Endereço: {endereco}</InfoLabel>
              </InfoLabelWrapper>
              <Divider />
              <InfoLabelWrapper>
                <InfoLabel>Unidade: {data.unidade_atual}</InfoLabel>
              </InfoLabelWrapper>
              <Divider />
              <InfoLabelWrapper>
                <InfoLabel>Custo: R$ {data.custo_atual.toFixed(2).toString().replace(".", ",")}</InfoLabel>
              </InfoLabelWrapper>
              <Divider />
              <FecharButton onClick={onClose}>FECHAR</FecharButton>
            </ModalInfoIntern>
          </ModalContent>
        </ModalWrapper>
      );
  };

export default ModalInfoMU;
