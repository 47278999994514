import React, { useState, useEffect, useRef } from 'react';
import { SelectorContainer, SelectorLabel, SelectorIcon, SelectorOptions, Option, SelectedOption, DivOption } from "./styles";

const DaySelector = ({ initialDays, onSelectDay }) => {
  const [selectedDays, setSelectedDays] = useState(initialDays);
  const [isOpen, setIsOpen] = useState(false);
  const selectorRef = useRef(null); 

  const handleLabelClick = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectChange = (event) => {
    const selectedDay = event.target.value;
    const index = selectedDays.indexOf(selectedDay);
    if (index === -1) {
      setSelectedDays([...selectedDays, selectedDay]);
    } else {
      const updatedDays = [...selectedDays];
      updatedDays.splice(index, 1);
      setSelectedDays(updatedDays);
    }
  };

  const isSelected = (day) => selectedDays.includes(day);

  useEffect(() => {
    onSelectDay(selectedDays);
  }, [selectedDays, onSelectDay]);

  return (
    <SelectorContainer>
      <SelectorLabel onClick={handleLabelClick}>
        <SelectorIcon></SelectorIcon> Tipo de dia
      </SelectorLabel>
      <SelectorOptions
        ref={selectorRef} 
        isOpen={isOpen}
        value={selectedDays}
        onChange={handleSelectChange}
        multiple
      >
        <Option value="U">Dia útil</Option>
        <Option value="S">Sábado</Option>
        <Option value="D">Domingo</Option>
      </SelectorOptions>
      <DivOption>
        {['Dia útil', 'Sábado', 'Domingo'].map((day, index) => (
          <SelectedOption
            key={index}
            isSelected={isSelected(['U', 'S', 'D'][index])}
            onClick={() => handleSelectChange({ target: { value: ['U', 'S', 'D'][index] } })}
          >
            {day}
          </SelectedOption>
        ))}
      </DivOption>
    </SelectorContainer>
  );
};

export default DaySelector;
