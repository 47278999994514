import React from 'react';
import { BlockTitle, CloseButton, ContentDiv, GerarButtonWrapper, InfoBlock, ModalContent, ModalWrapper, StyledTopBar, Text, TextDiv, Title } from './styles';
import BlueButton from '../BlueButton';

const ModalDO = ({ onClickGerar, onClose, selectedRota, rotas }) => {

    const renderLinhas = (linhas) => {
        let retorno = '';
        for (let i = 1; i <= linhas.num_linhas; i++) {
            retorno += (i > 1 ? ' ' : '') + linhas[`linha_${i}`].linha;
        }
        return retorno;
    };

    const renderInfoBlock = (day, label) => (
        <InfoBlock>
            <BlockTitle>{label}</BlockTitle>
            <Text>Ida: Residência/Trabalho</Text>
            <Text>
                {renderLinhas(rotas.rotas[day].ida[selectedRota.get(day).get('Ida')])}
            </Text>
            <Text>Volta: Trabalho/Residência</Text>
            <Text>
                {renderLinhas(rotas.rotas[day].volta[selectedRota.get(day).get('Volta')])}
            </Text>
        </InfoBlock>
    );

    return (
        <ModalWrapper>
            <ModalContent>
                <StyledTopBar>
                    <Title>Gerar Declaração de Opção</Title>
                </StyledTopBar>
                <CloseButton onClick={onClose}>X</CloseButton>
                <TextDiv>
                    <Text>Foram selecionadas as seguintes linhas</Text>
                </TextDiv>
                <ContentDiv>
                    {selectedRota.has('U') && renderInfoBlock('U', 'Dia útil')}
                    {selectedRota.has('S') && renderInfoBlock('S', 'Sábado')}
                    {selectedRota.has('D') && renderInfoBlock('D', 'Domingo')}
                </ContentDiv>
                <GerarButtonWrapper>
                    <BlueButton onClick={onClickGerar}>
                        Gerar
                    </BlueButton>
                </GerarButtonWrapper>
            </ModalContent>
        </ModalWrapper>
    );
};

export default ModalDO;
