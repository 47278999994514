import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, Navigate } from 'react-router-dom';
import Login from '../../pages/login';
import Home from '../../pages/home';
import Result from '../../pages/result';
import MelhorUnidade from '../../pages/melhor';
import { consultaPath, loginPath, melhorUnidadePath, resultadoPath } from '../pathConfig';
import getTimeOut from '../services/getTimeOut';

const PrivateRoute = ({ element, isAuthenticated, redirectTo = '/' }) => {
  return isAuthenticated ? element : <Navigate to={redirectTo} />;
};

const Routers = () => {
  const autenticate = () => {
    const storedToken = localStorage.getItem('token');
    
    const verify = async (token) => {
      const data = await getTimeOut(token);
      if(data.success) {
        console.log("Autenticando");
        return(true);
      } else {
        return(false);
      }
    }
  
    verify(storedToken);
  };

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path={loginPath} element={<Login />} />
      <Route path={consultaPath}  element={<PrivateRoute element={<Home />} isAuthenticated={autenticate} redirectTo={loginPath} />} />
      <Route path={resultadoPath} element={<PrivateRoute element={<Result />} isAuthenticated={autenticate} redirectTo={loginPath} />} />
      <Route path={melhorUnidadePath} element={<PrivateRoute element={<MelhorUnidade />} isAuthenticated={autenticate} redirectTo={loginPath} />} />
    </Routes>
  );
};

export default Routers;
