import axios from 'axios';
import { apiPath } from '../../pathConfig';

const getUnidadeDados = async (token, params) => { 
    const formData = new FormData();
    formData.append('function','getUnidadeDados');
    formData.append('token',token);
    // enviar id da unidade
    formData.append('params', JSON.stringify(params));
    
    const response = await axios.post(apiPath, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', 
        },
    });

    return response.data;
}

export default getUnidadeDados;
