import axios from 'axios';
import { apiPath } from '../../pathConfig';

const login = async (params) => {
    // Cria um objeto FormData
    const formData = new FormData();
    formData.append('function', 'login');
    formData.append('params', JSON.stringify(params));
    try {
        
        const response = await axios.post(apiPath, formData, {
            headers: { 
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao fazer login:', error);
        throw error;
    }
};

export default login;
