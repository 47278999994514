import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${cinza_texto};
  color: ${branco_primario};
  margin-right: 10px;
`;

export const ProfileName = styled.h2`
  margin: 0;
  font-size: 16px;
  font-family: ${font_base};
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  color: ${cinza_texto}; 

  &:hover {
    color: ${azul_primario}; 
    ${IconWrapper} {
      background-color: ${azul_primario}; 
    }
  }
`;

export const ArrowIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 100px;
  transform: translateY(-50%);
`;
