import React, { useEffect, useState } from 'react';
import { CloseButton, PopUpContent, PopUpWrapper, StyledTopBar, Title } from './styles';

const PopUpErro = ({ type, children }) => {
    
    return (
        <PopUpWrapper>
            <PopUpContent>
                <StyledTopBar>
                    <Title textColor={type == "error" ? "red" : "orange"}>{children}</Title>
                </StyledTopBar>
            </PopUpContent>
        </PopUpWrapper>
    );
};

export default PopUpErro;