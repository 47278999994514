import React from "react";
import { ListContainer, ListItem, Label } from "./styles";

const ListUnidades = ({ itens, onItemClick, label }) => {
    return (
        <>
        <Label>{label}</Label>
        <ListContainer>
            {itens.map((item, index) =>(
                <ListItem
                    key={index}
                    onClick={() => onItemClick(item)}
                >{item}</ListItem>
            ))}
        </ListContainer>
        </>
    );
};

export default ListUnidades;