import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${cinza_texto};
  margin: 20px auto;
  width: 80%; 
  position: relative;
  @media (max-width: 768px) {
    width: 80%; 

  }
`;

export const InputWrapperQtd = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.label`

  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  color: ${cinza_texto};
  font-size: 15px;
  pointer-events: none; /* Evita que o rótulo seja clicável */
  text-align: left; /* Alinha o texto à esquerda */
  font-family: ${font_base};

  /* Ajuste a posição conforme necessário */
  left: -70px; /* Adapte conforme necessário */

  @media (max-width: 100px) {
    font-size: 12px;
    left: -70px; /* Alinha o rótulo à esquerda quando a tela é pequena */
  }
`;


export const InputField = styled.input`
  padding: 8px;
  border: 1px solid ${cinza_texto};
  border-radius: 0.5px;
  font-size: 16px;
  width: 100%; /* Ocupa 100% da largura do container */
  font-family: ${font_base};

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 6px; 
  }

  outline: none;
  background-color: ${branco_primario};
`;


export const EyeIcon = styled.span`
  position: absolute;
  top: 70%;
  right: 1%;
  transform: translateY(-50%);
  cursor: pointer;
  color: rgba(43, 160, 225, 1);

  @media (max-width: 768px) {
    right: 5%; 
  }
`;
