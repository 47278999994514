import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const CampoTitulo = styled.div`
    color: ${cinza_texto};
    width: 95%;
    heigth: 30px;
    margin-left: 5%;
`;

export const CampoDados = styled.div`
    color: ${cinza_texto};
    width: 90%;
    heigth: 30px;
    margin-left: 10%;
`;

export const Linha = styled.hr`
    width: 95%;
    color: ${cinza_texto};
    font-family: ${font_base};
`;