import React from 'react';
import {SelectWrapper,Select, ArrowIcon} from './styles';

const CustomSelect = ({ options, value, onChange }) => {
  return (
    <SelectWrapper>
      <Select value={value} onChange={onChange}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <ArrowIcon>&#x25BC;</ArrowIcon>
    </SelectWrapper>
  );
};

export default CustomSelect;