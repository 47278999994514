import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_borda_light, cinza_texto } from '../../../data/color';

export const SelectorContainer = styled.div`
  margin: -50px 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative; 
`;

export const SelectorLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  color: ${cinza_texto};
  border: 1px solid ${cinza_texto};
  background-color: ${azul_primario_light};
  padding: 6px 12px;
  font-family: ${font_base};
`;

export const SelectorIcon = styled.span`
  margin-right: 10px;
  font-size: 12px;
  font-family: ${font_base};
`;

export const SelectorOptions = styled.select`
  padding: 10px;
  font-size: 16px;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  background-color: ${branco_primario};
  border: 1px solid ${cinza_texto};
  border-radius: 5px;
  margin-top: 5px;
  transition: all 0.3s ease;
  position: absolute; 
  top: calc(100% + 5px); 
  left: 0; 
  font-family: ${font_base};
`;

export const Option = styled.option`
  font-size: 16px;
  font-family: ${font_base};
`;

export const SelectedOption = styled.span`
  margin: 5px; 
  padding: 5px 10px;
  border-radius: 2px;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? azul_primario : azul_primario_light)};
  color: ${(props) => (props.isSelected ? branco_primario : cinza_texto)};
  transition: all 0.3s ease;
  font-family: ${font_base};
`;

export const DivOption = styled.div`
  display: flex; /* Adicionado para que os dias selecionados fiquem na mesma linha */
  align-items: center; /* Para alinhar os dias selecionados verticalmente */
`;