import React, { useState } from 'react';
import {
  Container,
  Label,
  Select,
  Option
} from "./styles";

const TimePicker = ({ label, initialTime, onChange }) => {
  const [selectedHour, setSelectedHour] = useState(initialTime.split(':')[0]);
  const [selectedMinute, setSelectedMinute] = useState(initialTime.split(':')[1]);

  const handleHourChange = (event) => {
    const hour = event.target.value;
    setSelectedHour(hour);
    onChange(`${hour}:${selectedMinute}`);
  };

  const handleMinuteChange = (event) => {
    const minute = event.target.value;
    setSelectedMinute(minute);
    onChange(`${selectedHour}:${minute}`);
  };

  return (
    <Container>
      <Label>{label}:</Label>
      <Select value={selectedHour} onChange={handleHourChange}>
        {Array.from({ length: 24 }, (_, i) => `${i < 10 ? '0' : ''}${i}`).map((hour) => (
          <Option key={hour} value={hour}>{hour}</Option>
        ))}
      </Select>
      
      <Select value={selectedMinute} onChange={handleMinuteChange}>
        {Array.from({ length: 60 }, (_, i) => `${i < 10 ? '0' : ''}${i}`).map((minute) => (
          <Option key={minute} value={minute}>{minute}</Option>
        ))}
      </Select>
    </Container>
  );
};

export default TimePicker;
