import styled, {keyframes} from 'styled-components';
import backgroundImg from '../../../utils/images/back_azul.png';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const Container = styled.div`
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh; 

`;
export const DepthBox = styled.div`
  position: relative;
  width: 30%; 
  height: 90%;
  background-color: ${branco_primario};
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  margin: 0% auto; 
  padding: 30vw; 
  overflow: hidden; 
  @media (max-width: 768px) {
    padding: 70vw;
  }
  @media (max-width: 1024px) {
    padding: 33vw;
  }
`;

export const Topbar = styled.div`
  background-color: ${branco_primario};
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  display: flex;
  @media (max-width: 768px) {
    padding: 0.0%;
  }
  img{
    width: 50px;
    height: 50px;
    margin: 5px;
  }
  
 `;

export const TopBarBlue = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%; 
  height: 2%;
  background-color: ${azul_primario}; 
`;
export const SubTitle = styled.h2`
  margin: 0;
  color: ${azul_primario};
  font-size: 16px;
  text-align: center;
  font-family: ${font_base};
`;

export const SubTitleWapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 100%;
  margin-left: 40%;
`;

export const ArrowIcon = styled.span`
  position: fixed;
  top: 3%;
  left: 80.5%;
  transition: background-color 0.3s; 
  font-family: ${font_base};
  &:hover {
    color: ${azul_primario}; 
  }
`;
export const ProfileWrapper = styled.div`

`;
export const ParallelInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  position: relative;

  
`;
export const BlueButtonWrapper = styled.div`

  margin-top: -10%;
  margin-left: 90%;
`;
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const LoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: ${rotate} 1s linear infinite;
`;

export const LoadingContainer = styled.div`
position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999; 
`;

export const MenuText = styled.p`
  margin: 0;
  padding: 0;
  color: ${azul_primario};
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  font-family: ${font_base};
`;