import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Link, Route, Routes} from "react-router-dom";
import BlueButton from '../../utils/components/BlueButton';
import {
  Container,
  DepthBox,
  TopBarBlue,
  MenuText,
  ParallelInputWrapper,
  LoadingSpinner,
  LoadingContainer,
  Divider,
  Title,
  TitleWapper,
  SmallDiv,
  TopBarBlueSmallDiv,
  WhiteTitle,
  VoltarButtonWrapper,
  ParallelGetWrapper,
  DividerWrapper,
  DaySelectorWrapper,
  DaySelectorButton,
  ResultDiv,
  FirtLinhaTitle,
  TempoTitle,
  TarifaTitle,
  TempoTitleWrapper,
  LinhaTitle,
  TarifaTitleWrapper,
  SelectMark,
  ResultWrapper,
  TextTopBarBlueSmallWrapper,
  ResultTableWrapper,
  MapButtonWrapper,
  MapButton,
  InfoButtonWrapper,
  InfoButton,
  InfoContainer,
  TopButtonDiv,
  DocButtonDiv,
  DocButtonWrapper,
  TopDocButtonDiv,
  TextTopBarBlueLinhasWrapper,
  TextTopBarBlueSmallLinhaWrapper,
  TextContentSmallWrapper,
  TextContentLinhasWrapper,
  TextContentSmallLinhaWrapper,
  MapInfoButtonWrapper
} from './styles';
import GetData from '../../utils/components/GetData';
import InputQuery from '../../utils/components/InputQuery';
import InputSmall from '../../utils/components/InputSmall';
import TopBar from '../../utils/components/TopBar';
import Menu from '../../utils/components/Menu';
import ModalPassword from '../../utils/components/ModalPassword';
import InputFields from '../../utils/components/InputFields';
import ModalInfo from '../../utils/components/ModalInfo';

import { FaCheck, FaXmark, FaMap, FaInfo, FaFilePdf } from 'react-icons/fa6';

import gerarRelTrajeto from '../../data/services/gerarRelTrajeto';
import { Select } from '../../utils/components/SelectState/styles';
import RotaInfoButton from '../../utils/components/RotaInfoButton';
import getTimeOut from '../../data/services/getTimeOut';
import changePass from '../../data/services/changePass';

import { consultaPath, loginPath, mapsPath, melhorUnidadePath } from '../../data/pathConfig';
import GreatBlueButton from '../../utils/components/GreatBlueButton';
import MediumBlueButton from '../../utils/components/MediumBlueButton';
import ModalDO from '../../utils/components/ModalDO';
import gerarDO from '../../data/services/gerarDO';

const Result = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { rotas, returnPath } = location.state;
  const [token, setToken] = useState(
    localStorage.getItem('token') ? localStorage.getItem('token') : '');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [loading, setLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Password, setPassword] = useState('');
  const [NewPassword, setNewPassword] = useState('');
  const [ConfirmPassword, setConfirmPassword] = useState('');
  const [nome, setNome] = useState('');
  const [matricula, setMatricula] = useState('');
  const [destino, setDestino] = useState('');
  const [menorCusto, setMenorCusto] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const [availableDays, setAvailableDays] = useState(new Set())
  const [resultado, setResultado] = useState([{}]);
  const [ida, setIda] = useState([]);
  const [volta, setVolta] = useState("");
  const [selectedRota, setSelectedRota] = useState(new Map());
  const [selectedIdaIndex, setSelectedIdaIndex] = useState(null);
  const [selectedVoltaIndex, setSelectedVoltaIndex] = useState(null);
  const [infoRota, setInfoRota] = useState('');
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isModalDOOpen, setIsModalDOOpen] = useState(false);
  const [editaFunc, setEditaFunc] = useState(localStorage.getItem("edita_func") == "true");

  useEffect(() => {

    //console.log(rotas);
    localStorage.setItem('actualRoute', JSON.stringify(rotas.rotas));
    localStorage.setItem('idResultado', JSON.stringify(rotas.id_resultado.rotas));

    const verify = async (token) => {
      const data = await getTimeOut(token);
      if(!data.success) {
        navigate(loginPath);
      }
    }
  
    if (token) {
      verify(token);      
    } else {
      navigate(loginPath);
    }
  });

  useEffect(() => {
    const rotasKeys = Object.keys(rotas.rotas);
    const updateState = (key, value) => {
      switch (key) {
        case 'U':
        case 'S':
        case 'D':
          if (value.nome_funcionario) setNome(value.nome_funcionario);
          if (value.matricula_funcionario) setMatricula(value.matricula_funcionario);
          if (value.nome_unidade) setDestino(value.nome_unidade);
          if (value.custo_mensal) setMenorCusto(value.custo_mensal.toFixed(2).toString().replace(".", ","));
          break;
        default:
          break;
      }
    };

    rotasKeys.forEach(key => {
      updateState(key, rotas.rotas[key]);
    });
  }, [rotas]);

  useEffect(() => {
    const auxSet = new Set();
    const daysSet = new Set();
    Object.keys(rotas.rotas).forEach(key => {
      if (key === 'U' || key === 'S' || key === 'D') {
        auxSet.add(key);
      }
    });

    const rota = new Map();

    if (auxSet.has('U')) {
      daysSet.add('U');
      const dia = new Map();
      dia.set('Ida', 0);
      dia.set('Volta', 0);
      rota.set('U', dia); 
    }
    if (auxSet.has('S')) {
      daysSet.add('S');
      const dia = new Map();
      dia.set('Ida', 0);
      dia.set('Volta', 0);
      rota.set('S', dia);
    }
    if (auxSet.has('D')) {
      daysSet.add('D');
      const dia = new Map();
      dia.set('Ida', 0);
      dia.set('Volta', 0);
      rota.set('D', dia);
    }

    setSelectedRota(rota);

    setAvailableDays(daysSet);
  }, [rotas]);

  useEffect(() => {
    if (availableDays.size > 0) {
      const daysArray = Array.from(availableDays);
      setSelectedDay(daysArray[0]);
    }
  }, [availableDays]);


  useEffect(() => {
    // para exibir resultados de acordo com os dias selecionados
    if (selectedDay) {
      const selectedDayData = rotas.rotas[selectedDay];
      setResultado(selectedDayData);
      setSelectedIdaIndex(selectedRota.get(selectedDay).get('Ida'));
      setSelectedVoltaIndex(selectedRota.get(selectedDay).get('Volta'));
    }

  }, [selectedDay]);

  useEffect(() => {
    if (resultado) {
      const ida = resultado.ida;
      const volta = resultado.volta;

      setIda(ida);
      setVolta(volta);
    }
    //console.log(ida);
  }, [resultado]);

  /*
  useEffect (() => {
    setSelectedIdaIndex(selectedRota.get(selectedDay).get('Ida'));
    setSelectedVoltaIndex(selectedRota.get(selectedDay).get('Volta'));
  }, [selectedRota])*/

  const handleOpenModal = () => {
    setIsModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsMenuOpen(false);
    setIsModalOpen(false);
  }

  const handleCloseMenu = () => {
    setIsModalOpen(false);
    setIsMenuOpen(false);
  }

  const handleCloseInfo = () => {
    setIsInfoOpen(false);
  }

  const handleCloseDO = () => {
    setIsModalDOOpen(false);
  }

  const handleEditPassword = async () => {
    const editparams = {
      senhaAntiga: Password,
      novaSenha: NewPassword,
    }
    const response = await changePass(token, editparams);
    if (response.success == true) {
      setIsMenuOpen(false);
      setIsModalOpen(false);
      alert("A senha foi trocada");
    }
    else {
      alert("Erro ao alterar senha");
    }
  }

  const handleBackButton = () => {
    if (returnPath == melhorUnidadePath) {
      navigate(returnPath, {state: { data: null, funcionario: null }})
    }
    navigate(returnPath);
  }

  const handleProfileClick = () => {
    setIsMenuOpen(true);
  };

  const handleDaySelect = (day) => {
    setSelectedDay(day);
  };

  const handleSelectIndex = (index, dia, tipo) => {
    const editRota = selectedRota;
    const diaMap = new Map();

    if(tipo == "Ida"){
      diaMap.set('Ida', index);
      diaMap.set('Volta', editRota.get(dia).get('Volta'));
    }
    if(tipo == "Volta"){
      diaMap.set('Ida', editRota.get(dia).get('Ida'));
      diaMap.set('Volta', index);
    }

    editRota.set(dia, diaMap);

    setSelectedRota(editRota);
  }

  const handleInfoButton = (rota) => {
    setInfoRota(rota);
    setIsInfoOpen(true);
  }

  const handleMapButton = (sentido, index) => {
    //console.log(rotas.id_resultado.rotas);
    window.open(mapsPath + "?tipoDia=" + selectedDay + "&sentido=" + sentido + "&opcao=" + index);
  }

  const handleOpenDO = () =>{
    setIsModalDOOpen(true);
  }

  const gerarParams = () => {
    let params = {};

    if(selectedRota.has('U')){
      params['routeU'] = [];
      params['routeU'].push(rotas.rotas.U.ida[selectedRota.get('U').get('Ida')]);
      params['routeU'].push(rotas.rotas.U.volta[selectedRota.get('U').get('Volta')]);
      params.endereco = rotas.rotas.U.endereco_funcionario;
      params.base = rotas.rotas.U.consulta.base;
    }
    if(selectedRota.has('S')){
      params['routeS'] = [];
      params['routeS'].push(rotas.rotas.S.ida[selectedRota.get('S').get('Ida')]);
      params['routeS'].push(rotas.rotas.S.volta[selectedRota.get('S').get('Volta')]);
      params.endereco = rotas.rotas.S.endereco_funcionario;
      params.base = rotas.rotas.S.consulta.base;
    }
    if(selectedRota.has('D')){
      params['routeD'] = [];
      params['routeD'].push(rotas.rotas.D.ida[selectedRota.get('D').get('Ida')]);
      params['routeD'].push(rotas.rotas.D.volta[selectedRota.get('D').get('Volta')]);
      params.endereco = rotas.rotas.D.endereco_funcionario;
      params.base = rotas.rotas.D.consulta.base;
    }

    params.idResultado = rotas.id_resultado;
    params.nome_func = nome.toLowerCase().split(' ').map(palavra => palavra.charAt(0).toUpperCase() + palavra.slice(1)).join(' ');
    params.unidade = destino;
    params.matricula = matricula;
    
    return params;
  }

  const handleGerarDO = async () => {
    let params = gerarParams();

    setLoading(true);
    const token = localStorage.getItem('token');
    const response = await gerarDO(token, params);
    if (response) {
      console.log(response);
      window.open(response.data);
    }
    setLoading(false);
    setIsModalDOOpen(false);
  }

  const handleGerarRelatorio = async () => {
    let params = gerarParams();

    setLoading(true);
    const token = localStorage.getItem('token');
    const response = await gerarRelTrajeto(token, params);
    if (response) {
      console.log(response);
      window.open(response.data);
    }
    setLoading(false);
  }

  const selectDay = (day) => {
    if(day == "U") {
      return "Dia útil";
    } else if (day == "S") {
      return "Sábado";
    } else if (day == "D") {
      return "Domingo";
    } else {
      return "None";
    }
  };

  return (
    <>
      <Container>
        <TopBar onProfileClick={handleProfileClick} />
        <DepthBox>
          <TopBarBlue>
            <WhiteTitle>Consulta / Cálculo do Transporte</WhiteTitle>
          </TopBarBlue>
          <ParallelInputWrapper>
            <InputQuery
              label="Funcionário"
              name="nome"
              value={nome}
              placeholder=" "
              isPassword={false}
              onChange={(e) => setNome(e.target.value)}
              readOnly={!editaFunc}
            />
            <InputSmall
              label="Matrícula"
              name="nome"
              value={matricula}
              placeholder=" "
              isPassword={false}
              onChange={(e) => setMatricula(e.target.value)}
              readOnly={!editaFunc}
            />
          </ParallelInputWrapper>
          <TopDocButtonDiv>
            <MediumBlueButton onClick={handleOpenDO}>
              <FaFilePdf></FaFilePdf>
              <h> Declaração de Opção</h>
            </MediumBlueButton>
            <MediumBlueButton onClick={handleGerarRelatorio}>
              <FaFilePdf></FaFilePdf>
              <h> Relatório de Trajeto</h>
            </MediumBlueButton>
          </TopDocButtonDiv>
          <VoltarButtonWrapper>
            <BlueButton onClick={handleBackButton}>VOLTAR</BlueButton>
          </VoltarButtonWrapper>
          <ParallelGetWrapper>
            <GetData
              label="Destino: "
              name="nome"
              value={destino}
              placeholder=" "
              isPassword={false}
              onChange={(e) => setDestino(e.target.value)}
            />
            {false &&
              <GetData
                label="Menor Custo: "
                name="nome"
                value={"R$" + menorCusto}
                placeholder=" "
                isPassword={false}
                onChange={(e) => setMenorCusto(e.target.value)}
              />
            }
          </ParallelGetWrapper>
          <DividerWrapper>
            <Divider></Divider>
          </DividerWrapper>
          <DaySelectorWrapper>
            {[...availableDays].map(day => (
              <DaySelectorButton
                key={day}
                selected={selectedDay === day}
                onClick={() => handleDaySelect(day)}
              >
                {selectDay(day)}
              </DaySelectorButton>
            ))}
          </DaySelectorWrapper>
          <TitleWapper>
            <Title>Ida: Residência / Trabalho</Title>
          </TitleWapper>
          <SmallDiv>
            <TopBarBlueSmallDiv>
              <TextTopBarBlueSmallWrapper >Seleção</TextTopBarBlueSmallWrapper>
              <TextTopBarBlueLinhasWrapper>
                {Array.from({ length: rotas.rotas[selectedDay]?.max_linhas}, (_, i) => (
                  <TextTopBarBlueSmallLinhaWrapper>Linha {i + 1}</TextTopBarBlueSmallLinhaWrapper>
                ))}
              </TextTopBarBlueLinhasWrapper>
              <TextTopBarBlueSmallWrapper >Tempo</TextTopBarBlueSmallWrapper>
              <TextTopBarBlueSmallWrapper >Tarifa</TextTopBarBlueSmallWrapper>
              <TextTopBarBlueSmallWrapper >Opções</TextTopBarBlueSmallWrapper>
            </TopBarBlueSmallDiv>
            <ResultTableWrapper>
              {
                ida && ida.length > 0 ? (
                  ida.map((rota, index) => (
                    <ResultDiv key={index}>
                      <TextContentSmallWrapper>
                        {(selectedIdaIndex == index) ? (
                            <>
                              <SelectMark color="#3DE33E" onClick={
                                () => {
                                  handleSelectIndex(index, selectedDay, "Ida");
                                  setSelectedIdaIndex(index);}
                            }>
                                <FaCheck style={{color: '#3DE33E'}}/>
                              </SelectMark>
                            </>
                          ) : (
                            <>
                              <SelectMark color="#0A1C5C" onClick={
                                () => {
                                  handleSelectIndex(index, selectedDay, "Ida");
                                  setSelectedIdaIndex(index);}
                              }>
                                <FaXmark style={{color: '#0A1C5C'}}/>
                              </SelectMark>
                            </>
                          )
                        }
                      </TextContentSmallWrapper>
                      <TextContentLinhasWrapper>
                        {(() => {
                          const linhas = rota.linhas.split(/\s+/);
                          const numPalavras = linhas.length;
                          while (linhas.length < 6) {
                            linhas.push('\u00A0');
                          }
                          return linhas.map((linha, linhaIndex) => {
                            return (
                              <TextContentSmallLinhaWrapper>{linha}</TextContentSmallLinhaWrapper>
                            );
                          });
                        })()}
                      </TextContentLinhasWrapper>
                      <TextContentSmallWrapper>
                        <TempoTitle>{rota.tempo} min</TempoTitle>
                      </TextContentSmallWrapper>
                      <TextContentSmallWrapper>
                        <TempoTitle>R$ {rota.tarifa.toFixed(2).toString().replace(".", ",")}</TempoTitle>
                      </TextContentSmallWrapper>
                      <TextContentSmallWrapper>
                        <MapInfoButtonWrapper>
                          <InfoButton onClick={() => handleInfoButton(rota)}>
                            <FaInfo style={{color: '#0A1C5C'}}/>
                          </InfoButton>
                          <MapButton onClick={() => handleMapButton("ida", index+1)}>
                            <FaMap style={{color: '#0A1C5C'}}/>
                          </MapButton>  
                          </MapInfoButtonWrapper>
                      </TextContentSmallWrapper>
                    </ResultDiv>
                  ))
                ) : (
                  <p>Nenhuma rota encontrada</p>
                )
              }
            </ResultTableWrapper>
          </SmallDiv>
          <TitleWapper>
            <Title>Volta: Trabalho / Residência</Title>
          </TitleWapper>
          <SmallDiv>
          <TopBarBlueSmallDiv>
              <TextTopBarBlueSmallWrapper >Seleção</TextTopBarBlueSmallWrapper>
              <TextTopBarBlueLinhasWrapper>
                {Array.from({ length: rotas.rotas[selectedDay]?.max_linhas}, (_, i) => (
                  <TextTopBarBlueSmallLinhaWrapper>Linha {i + 1}</TextTopBarBlueSmallLinhaWrapper>
                ))}
              </TextTopBarBlueLinhasWrapper>
              <TextTopBarBlueSmallWrapper >Tempo</TextTopBarBlueSmallWrapper>
              <TextTopBarBlueSmallWrapper >Tarifa</TextTopBarBlueSmallWrapper>
              <TextTopBarBlueSmallWrapper >Opções</TextTopBarBlueSmallWrapper>
            </TopBarBlueSmallDiv>
            <ResultTableWrapper>
              {
                volta && volta.length > 0 ? (
                  volta.map((rota, index) => (
                    <ResultDiv key={index}>
                      <TextContentSmallWrapper>
                        {(selectedVoltaIndex == index) ? (
                            <>
                              <SelectMark color="#3DE33E" onClick={() => {
                                handleSelectIndex(index, selectedDay, "Volta");
                                setSelectedVoltaIndex(index);}
                            }>
                                <FaCheck style={{color: '#3DE33E'}}/>
                              </SelectMark>
                            </>
                          ) : (
                            <>
                              <SelectMark color="#0A1C5C" onClick={() => {
                                  handleSelectIndex(index, selectedDay, "Volta");
                                  setSelectedVoltaIndex(index);}
                            }>
                                <FaXmark style={{color: '#0A1C5C'}}/>
                              </SelectMark>
                            </>
                          )
                        }
                      </TextContentSmallWrapper>
                      <TextContentLinhasWrapper>
                        {(() => {
                          const linhas = rota.linhas.split(/\s+/);
                          const numPalavras = linhas.length;
                          while (linhas.length < 6) {
                            linhas.push('\u00A0');
                          }
                          return linhas.map((linha, linhaIndex) => {
                            return (
                              <TextContentSmallLinhaWrapper>{linha}</TextContentSmallLinhaWrapper>
                            );
                          });
                        })()}
                      </TextContentLinhasWrapper>
                      <TextContentSmallWrapper>
                        <TempoTitle>{rota.tempo} min</TempoTitle>
                      </TextContentSmallWrapper>
                      <TextContentSmallWrapper>
                        <TempoTitle>R$ {rota.tarifa.toFixed(2).toString().replace(".", ",")}</TempoTitle>
                      </TextContentSmallWrapper>
                      <TextContentSmallWrapper>
                        <MapInfoButtonWrapper> 
                          <InfoButton onClick={() => handleInfoButton(rota)}>
                            <FaInfo style={{color: '#0A1C5C'}}/>
                          </InfoButton>
                          <MapButton onClick={() => handleMapButton("volta", index+1)}>
                            <FaMap style={{color: '#0A1C5C'}}/>
                          </MapButton> 
                        </MapInfoButtonWrapper>
                      </TextContentSmallWrapper>
                    </ResultDiv>
                  ))
                ) : (
                  <p>Nenhuma rota encontrada</p>
                )
              }
            </ResultTableWrapper>
          </SmallDiv>
        </DepthBox>
        <Menu isOpen={isMenuOpen} onClose={handleCloseMenu}>
          <MenuText onClick={handleOpenModal}>Trocar senha</MenuText>
          <ModalPassword isOpen={isModalOpen} onClose={handleCloseModal}>
            <InputFields
              label="Senha"
              name="nome"
              value={Password}
              placeholder=" "
              isPassword={true}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputFields
              label="Nova senha"
              name="nome"
              value={NewPassword}
              placeholder=" "
              isPassword={true}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <InputFields
              label="Confirmar senha"
              name="nome"
              value={ConfirmPassword}
              placeholder=" "
              isPassword={true}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <BlueButton onClick={handleEditPassword}>Salvar</BlueButton>
          </ModalPassword>
        </Menu>
        {isInfoOpen && (
          <ModalInfo rota={infoRota} onClose={handleCloseInfo} />
        )}
        {isModalDOOpen && (
          <ModalDO onClose={handleCloseDO} selectedRota={selectedRota} rotas={rotas} onClickGerar={handleGerarDO}/>
        )}
        {loading && (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        )}
      </Container>
    </>
  );
};
export default Result;
