import axios from 'axios';
import { apiPath } from '../../pathConfig';

const logout =  async(token) => {
    const formData = new FormData();
    formData.append('function', 'logOut');
    formData.append('token', token);
    const response = await axios.post(apiPath, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', 
        },
    });

    return response.data;
}

export default logout;