import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background: ${branco_primario};
  padding: 120px; 
  border-radius: 2px;
  position: relative;
  width: 20%;
  height: auto;
  overflow-y: auto; 
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: ${cinza_texto};
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: ${font_base};
  &:hover {
    color: ${branco_primario};
  }
 
`;

export const StyledTopBar = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%; 
  height: 15%;
  background-color: ${azul_primario}; 
 
`;
export const  Title = styled.h1`
  font-size: 25px;
  color: ${branco_primario};
  margin: 10px;
  padding: 0;
  text-align: center;
  line-height: 50px;
  margin-top: 20;
  font-family: ${font_base};
`;
