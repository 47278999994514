import React, { useState, useEffect, useRef } from 'react';
import { ModalWrapper, ModalContent, CloseButton } from './styles';

const Menu = ({ isOpen, onClose, marginTop, marginLeft, children }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();

      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    isOpen && (
      <ModalWrapper >
        <ModalContent ref={menuRef}>
          {children}
        </ModalContent>
      </ModalWrapper>
    )
  );
};

export default Menu;
