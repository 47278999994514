import styled, {keyframes} from 'styled-components';
import backgroundImg from '../../utils/images/back_azul.png';
import { font_base } from '../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../data/color';

export const Container = styled.div`
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh; 

`;

export const DepthBox = styled.div`
  position: relative;
  width: 30%; 
  height: 90%;
  background-color: ${branco_primario};
  border-radius: 5px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
  margin: 0% auto; 
  padding: 30vw; 
  overflow: hidden; 
  @media (max-width: 768px) {
    padding: 70vw;
  }
  @media (max-width: 1024px) {
    padding: 33vw;
  }
`;

export const TopBarBlue = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%; 
  height: 40px;
  background-color: ${azul_primario}; 
`;

export const WhiteTitle = styled.h2`
  font-weight: 300;
  color: ${branco_primario};
  font-size: 16px;
  margin-left: 10px;
  font-family: ${font_base};
`;

export const NomeContainer = styled.div`
  width: 80%;
  height: 50px;
  position: absolute;
  top: 70px;
  left: 10%;
  display: flex;
`;

export const NomeLabel = styled.label`
  top: 0%;
  color: ${cinza_texto};
  font-size: 16px;
  text-align: left; 
  left: -60px;
  font-family: ${font_base};
`;

export const NomeButton = styled.button`
  position: absolute;
  left: 80%;
  top: 5px;
  background-color: ${branco_primario};
  border-width: 0px;
  color: ${cinza_texto};
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: ${azul_primario};
  }
`;

export const DividerWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${azul_primario_light};
  top: 120px;
  left: 10%;
  width: 80%;
  position: absolute;
`;

export const SmallDiv = styled.div`
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  padding: 0vw;
  position: absolute;
  width: 90%;
  height: 300px;
  background-color: ${branco_primario};
  top: 160px;
  left: 5%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const TopBarBlueSmallDiv = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 30px;
  background-color: ${azul_primario};
  display: flex;
`;

export const TextTopBarBlueBigWrapper = styled.div`
  color: ${branco_primario};
  margin-top: 5px;
  width: 16%;
  height: 50px;
  display: flex;
  justify-content: center;
`;

export const TextTopBarBlueSmallWrapper = styled.div`
  color: ${branco_primario};
  margin-top: 5px;
  width: 10%;
  height: 50px;
  display: flex;
  justify-content: center;
`;

  export const ResultTableWrapper = styled.div`
  margin-top: 10px;
`;

export const ResultDiv = styled.div`
  display: flex; 
  flex-direction: row;
  flex-grow: 1;
  height: 1%;
  background-color: ${branco_primario};
  border-bottom: 1px solid #ccc;
  border-color: ${azul_primario_light};
  top: 20px;
  position: relative;
  
`;

export const SelectMarkWrapper = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectMark = styled.button`
    height: 17px;
    width: 28px;
    background-color: ${branco_primario};
    border: solid;
    border-width: 1px;
    border-radius: 10px;
    border-color: ${props => props.color};
    font-family: ${font_base};
`;

export const ResultWrapper = styled.div`
    height: 45px;
    width: 100%;
    display: flex;
`;

export const DataLabelWrapper = styled.div`
    width: 16%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DataLabel = styled.label`
    font-weight: 300;
    color: ${cinza_texto};
    font-size: 14px;
    text-align: center;
    margin-left: 10px; 
    font-family: ${font_base};
`;

export const ConsultaButtonContainer = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center;
  width: 10%;
`;

export const ConsultaButton = styled.button`
  background-color: ${({ isClicked }) => (isClicked ? azul_primario : azul_primario)};
  color: ${branco_primario};
  font-family: ${font_base};
  height: 30px;
  width: 50px;
  /* padding: 0.8% 20%; */
  border: none;
  border-radius: 20px;
  border:2px solid ${azul_primario};
  cursor: pointer;
  transition: background-color 0.3s; /* Adiciona uma transição suave */
  &:hover {
    background-color: ${branco_primario}; /* Cor ao passar o mouse */
    color: ${azul_primario};
  }

`; 

export const VoltarButtonWrapper = styled.div`
  position: absolute;
  width: 200px;
  weight: 100px;
  margin-top: -1%;
  margin-left: 90%;
`;

export const MenuText = styled.p`
  margin: 0;
  padding: 0;
  color: #0A1C5C;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  font-family: ${font_base};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const LoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: ${rotate} 1s linear infinite;
`;

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999; 
`;