const { protocol, hostname, port } = window.location;
const hostnamePart = hostname.split('/');
const host = `${protocol}//${hostnamePart[0]}`;
export const apiPath = `${host}/vt_site/api/routingSite.php`;

//export const apiPath = 'http://hml.vtreal.com.br/vt_site/api/routingSite.php';
//export const apiPath = 'http://localhost/vt_site/api/routingSite.php';
//export const apiPath = "http://ec2-54-71-241-35.us-west-2.compute.amazonaws.com/vt_site/api/routingSite.php";

export const relativePath = "";

export const loginPath = "/Login";
export const consultaPath = "/Consulta";
export const resultadoPath = "/Resultado";
export const melhorUnidadePath = "/MelhorUnidade"
export const mapsPath = "/vt_site/maps/html/maps.html";