import styled, {keyframes} from 'styled-components';
import backgroundImg from '../../utils/images/back_azul.png';
import { font_base } from '../../data/font';
import { azul_primario, branco_primario } from '../../data/color';

export const Container = styled.div`
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh; 

`;

export const DepthBox = styled.div`
  position: relative;
  width: 30%; 
  height: 50%;
  background-color: ${branco_primario};
  border-radius: 5px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
  margin: 0% auto; 
  padding: 30vw; 
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px; 
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1); 
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.04); 
    transform: translateX(-70%);
    z-index: 1; 
  }
  @media (max-width: 768px) {
    padding: 70vw;
  }
  @media (max-width: 1024px) {
    padding: 33vw;
  }
`;

export const TopBar = styled.div`
  background-color: ${branco_primario};
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  display: flex;
  @media (max-width: 768px) {
    padding: 0.0%;
  }
  img{
    width: 50px;
    height: 50px;
    margin: 5px;
  }
`;

export const TopBarBlue = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%; 
  height: 60px;
  background-color: ${azul_primario}; 
`;
export const SubTitle = styled.h2`
  margin: 0;
  color: ${azul_primario};
  font-size: 16px;
  text-align: center;
  font-family: ${font_base};
`;

export const SubTitleWapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 100%;
  margin-left: 40%;
`;

export const ArrowIcon = styled.span`
  position: fixed;
  top: 3%;
  left: 80.5%;
  transition: background-color 0.3s; 
  &:hover {
    color: ${azul_primario}; 
  }
`;
export const ProfileWrapper = styled.div`

`;

export const MenuText = styled.p`
  margin: 0;
  padding: 0;
  color: ${azul_primario};
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  font-family: ${font_base};
`;

export const TitleEmployee = styled.h1`
  margin: 0;
  color: ${branco_primario};
  font-size: 20px;
  margin-left: 20%;
  margin-top: 2%;
  font-family: ${font_base};
  font-weight: 300;

`;
export const TitleUnit = styled.h1`
  margin: 0;
  color: ${branco_primario};
  font-size: 20px;
  margin-left: 70%;
  margin-top: -2%;
  font-family: ${font_base};
  font-weight: 300;
`;
export const LeftDiv = styled.div`
display: flex;
margin-top: -50%;
height: 100%;
width: 100%;
margin-left: -80%;
flex-direction: column;
`;

export const RightDiv =  styled.div`
display: flex;
height: 100%;
width: 100%;
margin-left: 75%;
flex-direction: column;

@media screen and (max-width: 2560px) {
  margin-top: -380px;
}

`;
export const ParallelInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0%;
  
`;

export const SelectWrapper = styled.div`
  margin-top: -10%;
`;
export const SelectCityWrapper = styled.div`
  margin-top: 3%;
 
`;

export const BlueButtonWrapper = styled.div`
  margin-top: -10%;
  margin-left: 90%;
`;
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const LoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: ${rotate} 1s linear infinite;
`;

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999; 
`;

export const MessageWrapper = styled.div`
  margin-top: 10%;
 
`;

export const ListButtonDiv = styled.div`
  display: flex;
  margin-top: 10px;
  margin-botton: 10px;
  gap: 10px;
`;

export const ConfirmaButtonDiv = styled.div`
  margin-top: 10px;
  margin-left: 70%;
`;

export const ListItensDiv = styled.div`
  margin-top: -20%;
`;