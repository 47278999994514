import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${cinza_texto};
  margin: 2px auto;
  width: 90%;
  position: relative;
  top: -80px;
  @media (max-width: 768px) {
    width: 90%; 
    top: -60px;  
  }
`;

export const InputWrapperQtd = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.label`
  top: 0%;
  color: ${cinza_texto};
  font-size: 1rem;
  font-weight: 300;
  pointer-events: none;
  text-align: left; 
  position: relative;
  left: 20px; 
  font-family: ${font_base};
`;

export const InputField = styled.input`
  padding: 8px;
  border: none;
  border-bottom: 1px solid ${cinza_texto};
  font-size: 16px;
  width: 80%;
  position: relative;
  left: 10px; 
  color: ${cinza_texto};
  font-family: ${font_base};
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 6px;
    left: 10px;
  }
  outline: none;
  background-color: ${branco_primario};
`;

export const EyeIcon = styled.span`
  position: absolute;
  top: 70%;
  right: 1%;
  transform: translateY(-50%);
  cursor: pointer;
  color: rgba(43, 160, 225, 1);

  @media (max-width: 768px) {
    right: 5%;
  }
`;
