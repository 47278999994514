import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
  margin: 2px auto;
  width: 250%; 
  position: relative;
  @media (max-width: 768px) {
    width: 90%; 
    top: -60px;  

  }
  top: -80px;  
`;

export const InputWrapperQtd = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.label`
  top: 0%;
  color: ${cinza_texto};
  font-size: 1rem;
  font-weight: 300;
  pointer-events: none; 
  text-align: left; 
  left: -60px; 
  font-family: ${font_base};
 
  
`;


export const InputField = styled.input`
  padding: 8px 8px 8px 0; 
  border: none;
  border-bottom: 1px solid ${cinza_texto}; 
  font-size: 16px;
  width: 95%;
  text-align: left;
  font-family: ${font_base};
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 6px 6px 6px 0; 
  }
  outline: none;
  background-color: ${branco_primario};
  color: ${cinza_texto};
`;


export const EyeIcon = styled.span`
  position: absolute;
  top: 70%;
  right: 1%;
  transform: translateY(-50%);
  cursor: pointer;
  color: rgba(43, 160, 225, 1);
  font-family: ${font_base};

  @media (max-width: 768px) {
    right: 5%; 
  }
`;
