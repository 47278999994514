import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
    Topbar,
    SubTitle,
    SubTitleWapper,
    ProfileWrapper,
} from './styles';
import LogoutButton from '../LogoutButton';
import CustomSelect from '../SelectState';
import Profile from '../Profile';


import getEstadosDisponiveis from '../../../data/services/getState';
import logout from '../../../data/services/logout';
import getSaldo from '../../../data/services/saldo';
import getUserName from '../../../data/services/username';
import sairCliente from '../../../data/services/sairCliente';
import { loginPath } from '../../../data/pathConfig';


const TopBar = ({ onProfileClick }) => { 
    const navigate = useNavigate();
    const [token, setToken] = useState('');
    const [saldo, setsaldo] = useState('');
    const [name, setName] = useState('');
    const [estado, setEstado] = useState('');
  
    const stateOptions = Array.isArray(estado) ? estado.map((item) => {
        return { value: item, label: item };
    }) : [];

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            setToken(storedToken);
        }

    }, [token]);
    //get no saldo
    useEffect(() => {
        const saldo = async () => {
            const response = await getSaldo(token);
            if (response.success == true) {
                setsaldo(response.data);
            }
        }
        const username = async () => {
            const response = await getUserName(token);
            if (response.success == true) {
                setName(response.data);
            }
        }
        username();
        saldo();
    }, [token]);
    // get nas unidades

    //get dos estados
    useEffect(() => {
        const estadosEffect = async () => {
            const response = await getEstadosDisponiveis(token);
            if (response.success == true) {
                setEstado(response.data.estado);
            }
        };
        estadosEffect();
    }, [token]);
    // dando logout ao clicar no botão
    const handleLogout = async () => {
        const response = await logout(token);
        console.log(response);
        if (response.success == true) {
            localStorage.clear();
            navigate(loginPath);
        }
        else {
            alert("erro ao deslogar");
        }
    }

    const handleProfileClick = () => {
        if (onProfileClick) {
            onProfileClick();
        }
    };

    return (
        <>
            <Topbar ><img src={require('../../../utils/images/loginico.png')} alt="Blog" />
                <CustomSelect options={stateOptions} value="option1" onChange={() => { }} />
                <SubTitleWapper><SubTitle>Consultas Restantes: {saldo}</SubTitle></SubTitleWapper>
                <ProfileWrapper onClick={handleProfileClick}>
                    <Profile
                        name={name}
                    />
                </ProfileWrapper>
                <LogoutButton onClick={() => handleLogout()}>SAIR</LogoutButton>
            </Topbar>
            
        </>
    );
};
export default TopBar;

