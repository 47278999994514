import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center; 
`;

export const Button = styled.button`
  background-color: ${({ isClicked }) => (isClicked ? azul_primario : azul_primario)};
  color: ${branco_primario};
  height: 50px;
  width: 200px;
  /* padding: 0.8% 20%; */
  border: none;
  border-radius: 20px;
  border:2px solid ${azul_primario};
  font-size: 16px;
  font-family: ${font_base};
 
  transition: background-color 0.3s; /* Adiciona uma transição suave */
  &:hover {
    background-color: ${branco_primario}; /* Cor ao passar o mouse */
    color: ${azul_primario};
  }

`; 
