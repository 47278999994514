import styled from 'styled-components'
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const InputContainer = styled.div`
  display: flex;
  }
`;
export const ExternDiv = styled.div`
`;

export const InputField = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 0.5px;
  font-size: 16px;
  width: 16px;
  font-family: ${font_base};

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 6px; 
  }

  outline: none;
  background-color: ${branco_primario};
`;

export const SelectWrapper = styled.div`
  display: flex;
  color: ${cinza_texto};
  margin: 1px auto;
  width: 90%;
  position: relative;
  top: -80px;
  @media (max-width: 768px) {
    width: 90%; 
    top: -40px;  
  }
  padding: 5px;
`;

// Label para o select
export const TitleLabel = styled.label`
  top: 50%;
  transform: translateY(-50%);
  margin-left: -6.5%;
  color: ${cinza_texto};
  transform-origin: 0 100%;
  font-size: 1em;
  font-weight: 300;
  display: flex;
  font-family: ${font_base};
`;


// Label para o select
export const Label = styled.label`
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
  color: ${cinza_texto};
  transform-origin: 0 100%;
  font-size: 1em;
  font-weight: 300;
  display: flex;
  font-family: ${font_base};
`;
