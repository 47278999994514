import axios from 'axios';
import { apiPath } from '../../pathConfig';

const getSaldo = async (token) => { // Correção: remova a palavra 'function' aqui
    const formData = new FormData();
    //formData.append('token', token);
    // function call to the API
    formData.append('function', 'getSaldo');
    formData.append('token', token);
    const response = await axios.post(apiPath, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', 
        },
    });

    return response.data;
}

export default getSaldo;
