import styled from "styled-components"
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const ListContainer = styled.div`
    height: 150px;
    overflow-y: auto;
    border: 1px solid ${cinza_texto};
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
`;

export const ListItem = styled.div`
    padding: 2px;
    margin-bottom: 5px;
    cursor: pointer;
    color: ${cinza_texto};
    font-size: 14px;
    font-weight: 300;
    font-family: ${font_base};
`;

export const Label = styled.label`
  top: 0%;
  color: ${cinza_texto};
  font-size: 16px;
  font-weight: 300;
  pointer-events: none; 
  text-align: left; 
  left: -60px; 
  font-family: ${font_base};
`;