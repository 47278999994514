import styled from 'styled-components'
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const InfoButton = styled.button`
    background-color: ${azul_primario};
    border-width: 0px;
    color: ${branco_primario};
    width: 100%;
    heigth: 100%;
    text-align: left;
    font-size: 18px;
    font-family: ${font_base};
`;
