import axios from 'axios';
import { apiPath } from '../../pathConfig';

const getFuncionarios = async (token, params) => { 
    const formData = new FormData();
    formData.append('function','getFuncionarios');
    formData.append('token',token);
    formData.append('params', JSON.stringify(params));
    const response = await axios.post(apiPath, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', 
        },
    });

    return response.data;
}

export default getFuncionarios;
